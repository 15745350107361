:root {
  --main-bg-color: #F3F9FF;
  --accent-color: #FF9422;
}
html {
  background-color: #ffffff;
  box-sizing: border-box;
}

body {
  font-family: "Lato", sans-serif;
}

.body-wrapper {
  overflow-x: hidden;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  padding: 0;
  margin: 0;
}

pre {
  display: none;
}




/* Banner */
.banner {
  background: #FFFFFF;
  box-shadow: 0px 4px 105px rgba(34, 48, 74, 0.2);
  border-radius: 0px 0px 10px 10px;
  padding: 10px 20px 10px 10px;
  display: grid;
  gap: 30px;
  align-items: center;
  grid-template-columns: 0.8fr 2fr 0.7fr;
}

.banner .logo-box {
  background: #EAF2F8;
  border-radius: 10px;
}

.banner .logo-box a {
  background: url(../image/domainer_logo.svg);
  background-repeat: no-repeat;
  background-position: center center;
  color: transparent;
  display: block;
  font-size: 0;
  padding: 20px 30px;
  height: 138px;
}

.banner .title {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 60px;
  color: #090909;
}

.banner .info {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #7D7F82;
  position: relative;
  margin-left: 15px;
}

.banner .info:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 6px;
  height: 100%;
  margin-left: -15px;
  border-radius: 10px;
  background: #F27558;
}

.banner .domaner-btn {
  background: #F27558;
  transition: all 0.5s ease-in-out;
  border-radius: 10px;
  padding: 23px 5px;
  display: block;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  text-align: center;
  color: #000000;
}

.banner .domaner-btn:hover {
  background-color: rgb(249, 173, 141);
}

@media only screen and (max-width: 1090px) {
  .banner .title {
      font-size: 22px;
      line-height: 50px;
      margin-bottom: 10px;
  }

  .banner .info {
      font-size: 18px;
  }

  .banner .domaner-btn {
      font-size: 18px;
  }

  .banner .domaner-btn {
      font-size: 18px;
      line-height: 22px;
      display: table;
      margin: 0 auto;
      padding: 23px 30px;
  }
}

@media only screen and (max-width: 940px) {
  .banner {
      grid-template-columns: 1fr;
      padding: 10px;
  }

  .banner .logo-box a {
      background: url(../image/domainer_logo_mob.svg);
      background-repeat: no-repeat;
      background-position: center center;
      height: 66px;
  }

  .banner .title {
      line-height: 24px;
      text-align: center;
  }

  .banner .info {
      font-size: 16px;
      line-height: 20px;
      text-align: center;
  }

  .banner .info:before {
      content: none;
  }
}

@media only screen and (max-width: 440px) {
  .banner .domaner-btn {
      display: block;
      padding: 23px 0px;
  }
}



.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}
@media (min-width: 1400px) {
  .container {
    max-width: 1200px;
  }
}

h1 {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 48px;
  color: #52628C;
  padding-top: 70px;
  padding-bottom: 50px;
}

h1 span {
  font-weight: 900;
  font-size: 64px;
  line-height: 50px;
  color: #2C395A;
  display: block;
  padding-bottom: 10px;
}

p {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 30px;
  color: #2C395A;
}

@media only screen and (max-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 24px;
  }
}

h1 .thin {
  font-weight: 300;
}

h2 {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 800;
  font-size: 52px;
  line-height: 60px;
  color: #2C395A;
  padding-bottom: 28px;
}

@media only screen and (max-width: 768px) {
  h2 {
    font-size: 28px;
    line-height: 36px;
  }
}

p {
  font-size: 16px;
  line-height: 30px;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

.site-header {
  background: var(--main-bg-color);
}

.site-logo {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 25px;
  color: #2C395A;
  position: relative;
  padding-left: 65px;
  padding-top: 10px;
}

.site-logo::before {
  position: absolute;
  content: "";
  background-image: url(../image/365-logo.svg);
  width: 52px;
  height: 43px;
  left: 0;
  top: 0;
}

.logo-wrapper {
  padding-top: 40px;
  padding-bottom: 22px;
}
.site-header-inner {
  display: grid;
  grid-template-columns: 60% 1fr;
  padding-bottom: 110px;
  position: relative;
}

.site-header-inner::before {
  position: absolute;
  content: "";
  background-color: var(--main-bg-color);
  width: 60px;
  height: 60px;
  bottom: -30px;
    left: calc(50% - 30px);
    transform: rotate(45deg);
}

.center {
  text-align: center;
}

.header-description {
  padding-bottom: 50px;
}

.site-header-content p {
  width: 85%;
}

.site-header-img {
  position: relative;
}

.site-header-img::before {
  position: absolute;
  content: "";
  background-image: url(../image/hero-img.webp);
  background-size: contain;
  background-repeat: no-repeat;
  width: 598px;
  height: 430px;
  top: 0;
  left: 35px;
}

.articles {
  padding: 100px 0;
}

.news-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 45px;
  padding-top: 65px;
  padding-bottom: 50px;
}

.news-date {
  position: absolute;
  content: "26.05.2022";
  background-color: rgba(243, 249, 255, 0.9);
    top: 23px;
    left: 23px;
    color: #2C395A;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    padding: 5px 15px;
    border-radius: 35px;
}

.news-box {
    border-radius: 10px;
    box-shadow: 0px 0px 110px rgba(44, 57, 90, 0.2);
    padding: 14px 14px 26px 14px;
    border: 1px solid transparent;
    transition: all 0.2s ease-in-out;
    position: relative;
}

.news-box:hover {
  border: 1px solid var(--accent-color);
  box-shadow: none;
}

.news-box:hover .news-cta {
  color: var(--accent-color);
}

.news-img {
  width: 100%;
  height: 250px;
  background: white;
  border-radius: 10px;
  background-size: cover;
  position: relative;
}

/* .news-img::before {
  position: absolute;
  content: "28.05.2022";
  background-color: rgba(243, 249, 255, 0.9);
  top: 10px;
  left: 10px;
  color: #2C395A;

  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  padding: 5px 15px;
  border-radius: 35px;
} */

.news-title {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  color: #2C395A;
  padding-top: 20px;
  overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 3; /* number of lines to show */
           line-clamp: 3; 
   -webkit-box-orient: vertical;
   word-break: break-word;
}


.news-description {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #5F5F5F;
  overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2; 
   -webkit-box-orient: vertical;
   margin-top: 10px;
   word-break: break-word;
}

.news-cta {
  font-family: 'Mulish';
font-style: normal;
font-weight: 800;
font-size: 18px;
line-height: 45px;
color: #2C395A;
position: relative;
transition: all 0.2s ease-in-out;
cursor: pointer;
margin-top: 10px;
}

.news-cta::before {
  position: absolute;
  content: "";
  background-image: url(../image/arrow-right.svg);
  right: -29px;
  top: 6px;
  width: 21px;
  height: 11px;
}

.load-more-news {
  display: flex;
  justify-content: center;
}

.load-more {
  background: var(--accent-color);
  padding: 23px 68px;
  border-radius: 35px;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  color: #FFFFFF;
  cursor: pointer;
}


/* FAQ  */

.text-center h2{
  color: #000;
  font-weight: 300;
}

.faq {
  border-radius: 10px;
}

.faq-wrapper{
    padding-bottom: 100px;
  position: relative;
  z-index: 2;
}
.faq-section-wrapper{
  padding-bottom: 90px;
}
 .faq-wrapper {
  padding-top: 26px;
  }
  .center {
      padding-bottom: 25px;
  }

.faq.expanded+.faq {
  border-top: 0
}


.faq-title {
  align-items: center;
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.faq-title h3 {
  font-weight: 400;
  line-height: 25px;
  pointer-events: none;
  margin-bottom: 0 !important;
  margin-top: 0;
  padding: 20px 0;
}
.faq-btn {
  align-items: center;
  border: 0;
  border-radius: 25px;
  display: flex;
  font-size: 100px;
  font-weight: 200;
  height: 49px;
  justify-content: center;
  width: 49px;
  position: relative;

}

.faq.collapsed .faq-btn::before {
  position: absolute;
  content: "";
  width: 3px;
  height: 22px;
  background-color: var(--accent-color);
  right: 25px;
  border-radius: 10px;
  transition: all 0.2 ease-in-out;
}

.faq-btn::after {
  position: absolute;
  content: "";
  width: 22px;
  height: 3px;
  background-color: var(--accent-color);
  right: 16px;
  border-radius: 10px;
}

.faq.expanded .faq-btn::before {
  transform: rotate(90deg);
  transition: all 0.2s ease;
}

.faq.collapsed .faq-btn::before {
  transform: rotate(0);
}


.faq-btn:hover,.faq-title:hover {
  cursor: pointer
}

.faq {
  transition: background-color .2s linear,font-weight .2s linear,border-radius .2s linear;
  position: relative;
  padding-bottom: 0;
  padding-left: 40px;
  padding-right: 40px;
}

.faq:not(:last-of-type):after {
  position: absolute;
  content: "";
  width: 98%;
  height: 1px;
  background-color: #D1E8FF;
  bottom: 0;
  left: 1%;
}

.faq.expanded::after {
  content: none;
}

.faq-background-outer{
  border: 1px solid #3B495F;
  position: absolute;
  border-radius: 40px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.faq-content{
  position: relative;
  z-index: 2;
}

.faq .faq-body {
  color: #3B495F;
  font-size: 17px;
  line-height: 24px;
  padding-bottom: 44px;
  padding-top: 0px;
  width: 95%;
}
.faq.collapsed .faq-body {
  display: none
}
.faq-body a{
  font-weight: bold;
  color: #000;
  text-decoration: underline;
}

.faq-body p {
  font-weight: 500;
}

.faq-body p b {
  font-weight: 700;
}

.faq.collapsed {
    padding: 20px 40px;
}

.faq.expanded {
    padding-top: 20px;
    background-color: #fff;
    margin-top: -1px;
}

.faq-btn span {
  pointer-events: none
}

.faq.collapsed .faq-btn span.plus,.faq.expanded .faq-btn span.minus {
  display: block
}

.faq.collapsed .faq-btn span.minus,.faq.expanded .faq-btn span.plus {
  display: none
}
.faq.expanded .faq-title h3 {
  font-weight: 800
}
.text-center {
  text-align: center;
  color: #000;
}
.text-center p{
  padding-bottom: 50px;
  color: #000;
}
.faq-title h3{
  font-family: 'Mulish';
font-style: normal;
font-weight: 400;
font-size: 24px;
color: #2C395A;
}

.faq-body p{
  color: #000;
  text-align: left;
} 

.faq-wrapper h2,
.center {
      text-align: center;
}

.faq-bg {
  background: var(--main-bg-color);
}

.faq-wrapper {
  padding: 100px 0 80px;
}

.about {
  padding: 80px 0 40px;
}

.about-wrapper {
  display: grid;
  grid-template-columns: 1fr 55%;
}

.about p:not(:last-of-type) {
  padding-bottom: 25px;
}

.about-us-img {
  position: relative;
}

.about-us-img::before {
  position: absolute;
  content: "";
  background-image: url(../image/thank-you.webp);
  background-size: contain;
  background-repeat: no-repeat;
  width: 590px;
  height: 400px;
  left: -75px;
  top: 0;
}

.contact-cta {
  background-color: var(--accent-color);
  padding: 23px 75px;
  border-radius: 80px;
  display: inline-block;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  margin-top: 25px;
  color: #FFFFFF;
  cursor: pointer;
  position: relative;
}

.about-description {
  padding-bottom: 25px;
}


footer .container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
}

@media only screen and (max-width: 768px) {
  footer .container {
    flex-direction: column;
    padding-bottom: 24px;
  }
}

footer .copyright {
  color: #b1b1b1;
  font-size: 14px;
  margin-left: 100px;
}

.m-visible {
  display: none;
}

.m-hidden {
  display: initial;
}

.read-more-1 {
  display: none;
}

@media only screen and (max-width: 1200px) {
  .news-wrapper {
    grid-template-columns: repeat(2, 1fr);
    padding-top: 33px;
  }

  .site-header-img::before {
    left: 0;
  }
}

@media only screen and (max-width: 992px) {
  .hero-mobile-img {
    width: 100%;
    height: 250px;
    background-image: url(../image/hero-img.webp);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 25px;
  }
  
  h1 {
    padding-bottom: 25px;
  }

  .header-description {
    padding-bottom: 20px;
  }

  .site-header-inner {
    grid-template-columns: 1fr;
  }

  .site-header-img::before {
    content: none;
  }
  
  .site-header-content {
    text-align: center;
  }

  .site-header-content p {
    width: 100%;
  }

  .news-wrapper {
    gap: 25px;
  }

  .about-wrapper {
    grid-template-columns: 1fr;
  }

  .about-us-img::before {
    content: none;
  }

  .about-us-mobile {
    width: 100%;
    height: 250px;
    background-image: url(../image/thank-you.webp);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 25px;
  }

  .about-us-content {
    text-align: center;
  }
  
  footer .copyright {
    margin-left: 0;
  }

  .m-slide-down {
    height: 0;
    overflow: hidden;
    -moz-transition: height .5s;
    -ms-transition: height .5s;
    -o-transition: height .5s;
    -webkit-transition: height .5s;
    transition: height .5s;
  }
  .read-more-1>*{
    pointer-events: none;
  }
  .read-more-1.close,  .collapse-1.close{
  display: none;
  }
  
  .m-readmore-btn-wrapper {
    display: flex;
    justify-content: center;
    position: relative;
  }
  
  .read-more-1 {
    padding: 15px 70px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 26px;
    text-align: center;

    color: #FF9422;
    position: relative;
    cursor: pointer;
    background-color: #fff;
    z-index: 2;
    display: block;
  }

  .about-description {
    padding-bottom: 0;
  }
}

@media only screen and (max-width: 768px) {
  
  h1 {
    font-size: 24px;
    line-height: 32px;
    padding-top: 5px;
    margin-bottom: 0;
  }

  h1 span {
    font-size: 38px;
    line-height: 50px;
    padding-bottom: 0;
  }

  .site-header-inner {
    padding-bottom: 50px;
  }

  .news-wrapper {
    grid-template-columns: 1fr;
  }

  .articles {
    padding: 65px 0;
  }

  .container {
    padding: 0 20px;
  }

  .mobile-detector {
    display: none;
  }

  .m-visible {
    display: initial;
  }

  .m-hidden {
    display: none;
  }

  footer .logo-wrapper {
    padding-bottom: 8px;
  }
}
